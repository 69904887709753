.App {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: darksalmon;
    height: 100vh;
}

.Paper {
    background-color: white;
    background-image: url("./handmade-paper.png");
    flex: 1;
    height: 100vh;
}