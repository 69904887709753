a {
    text-decoration: none;
    color: inherit;
}



h1 {
    color: #5d5d5d;
    font-size: 70px;
    font-weight: lighter;
    line-height: 0;
    text-shadow: 0 1px 1px #4d4d4d;
    user-select: none;
}

@media only screen and (max-width: 900px) {
    h1 {
        color: #5d5d5d;
        font-size: 48px;
        font-weight: lighter;
        line-height: 0;
        text-shadow: 0 1px 1px #4d4d4d;
        user-select: none;
    }
}

h2 {
    padding-left: 4px;
    color: #5d5d5d;
    font-size: 30px;
    font-weight: lighter;
    line-height: 16px;
    text-shadow: 0 1px 1px #4d4d4d;
    user-select: none;
}

p {
    line-height: 10px;
    font-size: 24px;
    color: #5d5d5d;
    text-shadow: 0 1px 1px #4d4d4d;
    user-select: none;
}

.social-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}